import { createI18n } from "vue-i18n/index";

const messages = {
  en: {
    test: "test試",
    langBtn: "中文",

    //Footer
    aboutUs: "About Us",
    adsContact: "Business",
    privacy: "Privacy & Disclaimer",

    //Sports
    tennis: "Tennis",
    tt: "Table Tennis",

    //Region
    hk: "Hong Kong",
    syd: "Sydney",

    //Top Banner
    officialPartner: "Official Partner",

    //Toolbar Breadcrumb
    toolbarHome: "Home",

    //Admin
    adminTour: "Tournament",
    adminTourGrouping: "Grouping",
    displayApplyInfo: "Input Single Tour ID to Display Applicant Information",
    displayApplyInfoDouble: "Input Double Tour ID to Display Applicant Information",
    contactCsv: "Download Contact CSV for the current tennis event",
    adminDownload: "Download",
    switchPlayers: "Switch Group Players",
    updageEvent: "Update all groups in an event",
    updageGroup: "Update a group",
    switchBtn: "Switch",
    prize: "Prize",
    update: "Update",
    address: "Address",
    updateAddress: "Update Address",
    paidPrize: "Paid Prize",
    mailedTrophy: "Mailed Trophy",
    adminPrizeList: "Input event to display prize list (#,#,#...)",
    display: "Display",
    prizePlace: "Place",
    trophyName: "Trophy Name",
    firstPlace: "1st",
    secondPlace: "2nd",
    thirdPlace: "3rd",
    copyPrizeMessage: "Copy Sending Message",
    copySentPrizeMessage: "Copy Sent Message",
    copyMessage: "Copy Message",
    copied: "Copied Message",
    count: "Count",
    filename: "File Name",
    notice: "Notice",
    messageContent: "Notice Message",
    function: "Function",
    groupRank: "Group Rank",
    miniRound: "MiniSlam Round",
    semiRound: "SemiSlam Round",
    clear: "Clear",
    create: "Create",
    createSingleGames: "Create All Singles Games by Group ID",
    createDoubleGames: "Create All Doubles Games by Group ID",
    createSingleGamesSuccess: "Created Single Games",
    createDoubleGamesSuccess: "Created Double Games",
    createFail: "Fail to create",
    
    //Left Side Menu
    asideMenuTour: "Tournament",
    asideMenuDraw: "Draw",
    asideMenuSchedule: "Schedule",
    asideMenuReport: "Report",
    asideMenuRank: "Ranking",
    asideMenuNews: "News",
    asideMenuContact: "Contact Us",
    asideMenuLogin: "Log in",
    asideMenuLang: "中文",
    asideMenuRegion: "Region",
    asideMenuSport: "Sport",
    asideTennisCoach: "Coach",
    asideTennisTraining: "Training",
    asideTennisClub: "Club",
    asideTennisShop: "Shop",
    asideRoCP: "Admin Panel",
    asideDiscuzCP: "Discuz Panel",

    //Tourframe
    frameTourInfo: "Info",
    frameTourDraw: "Draw",
    frameTourApply: "Sign Up",
    frameTourPay: "Fee",
    frameTourPlayers: "Players",
    frameTourContact: "Contacts",

    //Tourinfo Page
    tourIntro: "Intro",

    //Tourlist Page
    pageTourList: "Tournament",
    tourName: "Tournaments",
    tourType: "Type",
    entrySeparate: "Need to register separately",
    tourStyle: "Format",
    applyStart: "Entries",
    applyEnd: "Deadline",
    tourStatus: "Status",
    tourLink: "Link",
    開放報名: "Open Registration",
    已截止: "Registration Ended",
    正在進行: "In Progress",
    完結: "Finished",
    準備中: "Preparing",
    單打: "Singles",
    雙打: "Doubles",
    團體: "Team",
    小組循環: "Round-Robin",
    淘汰: "Elimination",
    tourInfo: "INFO",
    tourDraw: "DRAW",
    tourApply: "REGISTER",
    tourList: "Tournament List",
    tourTimetable: "Tournament Timetable",
    tourSeries: "Tournament Series",
    totalPrize: "Total Prize",
    inProgress: "In Progress",
    regEnd: "Registration Ended",
    tourEnd: "Finished",
    preparing: "Preparing",
    startReg: "Open Registration",
    menSingle: "Men's Singles",
    ladieSingle: "Ladie's Singles",
    allSingle: "All Gender's Singles",
    allDouble: "All Gender's Doubles",
    ladieDouble: "Ladie's Doubles",
    mixDouble: "Mixed Doubles",

    //Tour Timetable
    roundRobin: "Round-Robin",
    round_robin: "Round-Robin",
    elimination: "Elimination",
    eliminate: "Elimination",
    buddiesWinter: "Hong Kong Tennis \"Buddies Cup\" (Winter)",
    buddiesSummer: "Hong Kong Tennis \"Buddies Cup\" (Summer)",
    fakeCup: "Hong Kong Tennis \"Fake Cup\"",
    miniSlam: "Hong Kong Tennis \"Mini Slam\"",
    allGender: "All Gender",
    singles: "Singles",
    doubles: "Doubles",
    men: "Men's ",
    ladie: "Ladie's ",
    tourPeriod: "Period",
    maybeTitle: "Variance",
    maybeMessage: "The final arrangement depends on the actual situation and may not be held as scheduled",

    

    //Rank Page
    rankSingle: "Singles",
    rankDouble: "Doubles",
    rankNumber: "Ranking",
    rankPlayer: "Player",
    rankPoints: "Points",
    updating: "Updating",
    rankRate: "Winrate",
    eloNote: "Update once every midnight using ELO ranking system",
    rankTwoYears: "Only include active players within 2 years",
    in2year: "Within 2 years",

    // Draw Page
    round1: 'R1',
    round2: 'R2',
    round3: 'R3',
    round4: 'R4',
    round5: 'R5',
    round6: 'R6',
    round7: 'R7',
    roundQF: 'QF',
    roundSF: 'SF',
    roundF: 'F',
    noTime: 'N/A',
    noPlace: 'N/A',
    noName: 'N/A',
    orBefore: 'Deadline:',
    seed: 'Seeded Players',
    drawRank: 'Group Rank',
    completeGame: 'Completed',
    allWin: 'Won',
    winGame: 'Net Game',
    winSet: 'Net Set',
    tempPoint: 'Points',
    groupDeadline: 'Deadline',
    noGRank: 'N/A',
    records: 'Records',
    penaltyPt: 'Penalty',
    noPenalty: 'N/A',
    byeDraw: 'BYE',
    winner: 'Winner',
    playerRankings: 'Player Rankings',
    groupPoints:'Group Points',

    //Contact Page
    contactUs: "Contact Us",
    emergencyContact: "Emergency Contact",
    businessContact: "Business Contact",
    emergencyNote: "Emergency Only",
    generalEnquiry: "General Enquiry",

    //Log in Page
    login: "Log in",
    register: "Sign Up",
    newhere: "New Here?",
    createAnAccount: "Create an Account",
    username: "Username",
    password: "Password",
    forgotPassword: "Forgot Password ?",
    fblogin: "Facebook Log in",
    loginSuccess: "Successfully Logged in",
    loginFail: "Login Unsuccessful",
    loggedOut: "Logged out",
    logout: "Log out",
    alreadyLogin: "Already Logged in",
    loginAgain: "Please Log in again",
    pleaseLogin: "Please Log in",
    inputUsername: "Please input username",
    inputPassword: "Please input password",

    //Register Page
    alreadyRegister: "Already have an account?",
    loginHere: "Log in here",
    confirmPassword: "Confirm Password",
    submit: "Submit",
    OR: "OR",
    normalRegister: "Regular Sign Up",
    registerFail: "Sign Up Unsuccessful",
    registerSuccess: "Sign Up Successfully, Please Log In",
    minUsername: "Minimum 3 Characters",
    maxUsername: "Maximum 15 Characters",
    requiredUsername: "From 3 to 15 Characters",
    noSpaceAllow: "No spaces are allowed",
    usedUsername: "Username Already Exists",
    usernameNote: "For Public Display, Can't Modify",
    phoneLabel: "Mobile",
    phoneNote: "For WhatsApp Communication",
    minPassword: "Minimum 6 Characters",
    maxPassword: "Maximum 18 Characters",
    matchPassword: "Password do not match",
    emailFormat: "Email Format Incorrect",
    requiredEmail: "Please Input Email",
    usedEmail: "Email has been registered",
    requiredMobile: "Please Input Mobile Number",
    facebookExist: "Facebook user already exist, please contact us if needed",

    //Forgot Password
    recoverPassword: "Recover Password",
    emailNote: "The Email when you register",
    recoverFail: "Fail to recover password",
    noEmail: "Email does not exist",
    emailFail: "Unable to send email, Please contact us",
    emailSent: "Reset password email has been sent",
    emailSentMessage: "Please check your mail box and junk mails.",

    //Reset Password
    resetBtn: "Reset Password",
    resetFail: "Unable to reset password, please contact us",
    passwordFail: "Password contain illegal character",
    resetSuccess: "Reset password success, please try to log in",

    //Account Setting
    accountSetting: "Account Settings",
    setProfile: "Profile",
    setPasswordEmail: "Password & Email",
    usernameLabel: "Username",
    balance: "Credits",
    area: "Preferred Match District",
    mobile: "Mobile",
    gender: "Gender",
    birthday: "Birthday",
    selfIntro: "Profile Description",
    practiceAfter: "Practice after match",
    saveBtn: "Save Changes",
    regionPH: "Your preferred district to play",
    region0: "Other",
    region1: "Central and Western District",
    region2: "Wan Chai",
    region3: "Eastern District",
    region4: "Southern District",
    region5: "Yau Tsim Mong",
    region6: "Sham Shui Po",
    region7: "Kowloon City",
    region8: "Wong Tai Sin",
    region9: "Kwun Tong",
    region10: "Kwai Tsing",
    region11: "Tsuen Wan",
    region12: "Tuen Mun",
    region13: "Yuen Long",
    region14: "North District",
    region15: "Tai Po",
    region16: "Sha Tin",
    region17: "Sai Kung",
    region18: "Islands",
    genderOther: "Other",
    genderMale: "Male",
    genderFemale: "Female",
    practiceYes: "Yes",
    practiceNo: "No",
    practiceOther: "Other",
    settingSuccess: "Saved",
    settingError: "Fail Saving",
    oldPassword: "Current Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    inputOldPassword: "Please input your current password",
    matchConfirmPassword: "'New Password' do not match 'Confirm New Password'",
    inputNewConfirmPassword: "Please input 'Confirm New Password'",
    samePassword: "'New Password' can not be the same as 'Current Password'",
    setAvatar: "Avatar",
    avatarType: "Allowed File Type: jpg, jpeg, png",
    avatarWidthHeight: "Suggested Width & Height: 300 x 300 px",
    avatarSize: "Maximum File Size: 1000 KB",
    avatarNoImage: "Please select an image",
    fbconnect: "Facebook Connect",
    fbconnectFail: "Facebook Connect Failed",
    fbconnectSuccess: "Facebook Connected",

    //User Page
    drawPlayer: "Player",
    scoreDraw: "Draw",
    singleRank: "Singles Rank",
    singleHighRank: "Highest Singles Rank",
    doubleRank: "Doubles Rank",
    doubleHighRank: "Highest Doubles Rank",
    winLose: "Win / Lose",
    recentMatch: "Recent Matches",
    matchRecord: "Match Records",
    winLosePoint: "Result",
    rankPoint: "Points",
    reschedule: "Reschedule",
    rescheduled: "Rescheduled",
    normal: "Normal",
    noRecord: "No Record",
    time: "Time",
    place: "Location",
    tempScore: "Current Score",
    group: "Group",
    score: "Score",
    won: "Won",
    lost: "Lost",
    points: " Points",
    retire: "Retire",
    drawEven: "Draw",
    notCount: "Disabled",
    tour: "Tournaments",
    single: "Singles",
    double: "Doubles",

    // Schedule Pages
    round: "Round",
    opponent: "Opponent",
    contact2: "Contact",
    deadline2: "Deadline",
    repoint: "Report",
    submitter: "Submitter",
    submitTime: "Submit Time",
    editRecord: "Last 5 edit record",
    oppoLocation: "Opponent Location",
    byeRound: "BYE",
    ifDone: "Completed",
    notDone: "Pending",
    sStatus: "Status",
    yesStay: "Yes",
    noStay: "No",
    cwd: "Central and Western",
    wanChai: "Wan Chai",
    eastD: "Eastern",
    southD: "Southern",
    ytm: "Yau Tsim Mong",
    ssp: "Sham Shui Po",
    klnCity: "Kowloon City",
    wts: "Wong Tai Sin",
    kwunTong: "Kwun Tong",
    kwaiTsing: "Kwai Tsing",
    tsuenWan: "Tsuen Wan",
    tuenMun: "Tuen Mun",
    yuenLong: "Yuen Long",
    northD: "North ",
    taiPo: "Tai Po",
    shaTin: "Sha Tin",
    saiKung: "Sai Kung ",
    island: "Island",
    other: "Other",
    quitTour: "Withdrawal",
    noGame: "No Data",

    //Report Page
    confirm: "Confirm",
    cancel: "Cancel",
    set1: "SET 1",
    set2: "SET 2",
    set3: "SET 3",
    tiebreak: "TIEBREAK",
    updated: "Updated",
    updateFailed: "Update Failed, Please contact us",
    haveWinner: "Can't update completed matches, Please contact us if needed",
    pleaseReset: "Please reset to update completed matches",
    remark: "Remark",
    success: "Success",
    fail: "Fail",
    error: "Error",
    date: "Date",
    startTime: "Start Time",
    player: "Player",
    reset: "Reset",
    

    //Apply Players
    order: "Order",
    payFee: "Payment",
    paid: "Paid",
    notPaid: "Unpaid",
    penalty: "Penalty",
    adminNote: "Admin Note",
    adminAction: "Action",
    adminPaid: "Paid",
    adminPaidDepo: "Paid & Deposit",
    adminWithdraw: "Withdraw",
    adminQuitTour: "Quit",
    adminPaidSuccess: "Udated Paid",
    adminWithdrawSuccess: "Withdraw Success",
    adminQuitTourSuccess: "Quit Tour Success",
    newbie: "Newbie",
    partner: "Partner",
    addPlayer: "Single Apply Manually (UID)",
    addDoublePlayer1: "Double Apply Manually Player 1 (UID)",
    addDoublePlayer2: "Double Apply Manually Player 2 (UID)",

    //Apply Tour
    appliedTitle: "Sign Up Successful",
    applied: "has sign up",
    partnerPay: "Need to be paid by partner",
    applyStartTitle: "Sign Up Begin",
    applyEndtTitle: "Sign up passed deadline",
    tourStartTitle: "Tournament Started",
    tourEndTitle: "Tournament Ended",
    tourPrepareTitle: "Tournament preparing stage",
    appliedContent: "Sign Up Successful, contact us for any enquiry",
    applyStartContent: "Please pay the application fee on time, contact us for any enquiry",
    applyEndContent: "Sign up passed deadline, contact us for any enquiry",
    tourStartContent: "Tournament Started, contact us for any enquiry",
    tourEndContent: "Tournament Ended, contact us for any enquiry",
    tourPrepareContent: "Tournament preparing stage, contact us for any enquiry",
    confirmApply: "Confirm Sign Up",
    confirmInfo: "Confirm Information",
    modifyApply: "Modify Information",
    memo: "Remark",
    partnerUsername: "Partner Username",
    requiredPartnerUsername: "Please input partner username",
    applyBtn: "Sign Up",
    yourMobile: "Your Mobile",
    partnerUsernameError: "Partner Username Error",
    applyGenderError: "Gender and tournament not match",
    partnerReg: "Partner Username Not Found",
    uniPartnerRegErr: "All Gender's Doubles Partner Username Not Found",
    mixPartnerRegErr: "Mixed Doubles Partner Username Not Found",
    womenPartnerRegErr: "Women's Doubles Partner Username Not Found",
    memoNote: "Tournament reference or remarks; any records might result in higher group",
    fakeCantApply: "Not eligible, Please contact us if needed",
    emptyTour: "Please select tournament",
    applyFeeCount: "Total Fee: ",
    applyFee: "Entry Fee",
    deposit: "Deposit",
    creditLeft: "Credit",

    //Payment
    eachDouble: "Each Team",
    payNote: "If you experience any problems, please press F5 to refresh. Feel free to contact us if the issue persists.",
    hkd: "HKD",
    clickPay: "Pay Online ",

    //Error Page
    msg404: "Seems there is nothing here",
    returnHome: "Return Home",
    systermError: "System Error",
    gotoHome: "Go to homepage",
    somethingWrong: "Something went wrong! Please try again later.",


  },
  hk: {
    test: "test試",
    langBtn: "English",

    //Footer
    aboutUs: "關於我們",
    adsContact: "商業合作",
    privacy: "隱私條款",

    //Sports
    tennis: "網球",
    tt: "乒乓球",

    //Region
    hk: "香港",
    syd: "悉尼",

    //Top Banner
    officialPartner: "冠名贊助",

    //Toolbar Breadcrumb
    toolbarHome: "主頁",

    //Admin
    adminTour: "比賽",
    adminTourGrouping: "比賽分組",
    displayApplyInfo: "輸入單打 Tour ID 顯示參加者資料",
    displayApplyInfoDouble: "輸入雙打 Tour ID 顯示參加者資料",
    contactCsv: "下載當前比賽的聯絡.CSV檔案",
    adminDownload: "下載",
    switchPlayers: "在組別中轉換球員",
    updageEvent: "更新項目中所有分數",
    updageGroup: "更新組別分數",
    switchBtn: "轉換",
    prize: "獎勵",
    update: "更新",
    address: "地址",
    updateAddress: "更新地址",
    paidPrize: "已付獎勵",
    mailedTrophy: "已寄出",
    adminPrizeList: "輸入項目顯示得獎者 (#,#,#...)",
    display: "顯示",
    prizePlace: "名次",
    trophyName: "獎座名稱",
    firstPlace: "第一名",
    secondPlace: "第二名",
    thirdPlace: "第三名",
    copyPrizeMessage: "複製送獎通知",
    copySentPrizeMessage: "複製已送通知",
    copyMessage: "複製通知",
    copied: "已複製訊息",
    count: "數目",
    filename: "檔案名",
    notice: "通知",
    messageContent: "通知內容",
    function: "功能",
    groupRank: "組別排名",
    miniRound: "小滿貫圈次",
    semiRound: "偽滿貫圈次",
    clear: "清空",
    create: "建立",
    createSingleGames: "建立單打組別所有循環場次",
    createDoubleGames: "建立雙打組別所有循環場次",
    createSingleGamesSuccess: "成功建立單打組別所有循環場次",
    createDoubleGamesSuccess: "成功建立雙打組別所有循環場次",
    createFail: "建立失敗",


    //Right Side Menu
    asideMenuTour: "比賽",
    asideMenuDraw: "簽表",
    asideMenuSchedule: "賽程",
    asideMenuReport: "報分",
    asideMenuRank: "排名",
    asideMenuNews: "最新消息",
    asideMenuContact: "聯絡我們",
    asideMenuLogin: "登入",
    asideMenuLang: "English",
    asideMenuRegion: "地區",
    asideMenuSport: "運動",
    asideTennisCoach: "教練",
    asideTennisTraining: "培訓",
    asideTennisClub: "球會",
    asideTennisShop: "店舖",
    asideRoCP: "控制中心",
    asideDiscuzCP: "Discuz 中心",

    //Tourframe
    frameTourInfo: "比賽詳情",
    frameTourDraw: "簽表",
    frameTourApply: "報名",
    frameTourPay: "繳費",
    frameTourPlayers: "參賽者",
    frameTourContact: "賽程 / 聯絡",

    //Tourinfo Page
    tourIntro: "介紹",

    //Tourlist Page
    pageTourList: "比賽",
    tourName: "比賽",
    tourType: "項目",
    entrySeparate: "需要獨立報名",
    tourStyle: "賽制",
    applyStart: "開始報名",
    applyEnd: "截止報名",
    tourStatus: "狀態",
    tourLink: "連結",
    開放報名: "開放報名",
    已截止: "已截止",
    正在進行: "正在進行",
    完結: "完結",
    準備中: "準備中",
    單打: "單打",
    雙打: "雙打",
    團體: "團體",
    小組循環: "小組循環",
    淘汰: "淘汰",
    tourInfo: "詳情",
    tourDraw: "簽表",
    tourApply: "報名",
    tourList: "比賽例表",
    tourTimetable: "比賽時間表",
    tourSeries: "比賽系列",
    totalPrize: "總獎金",
    inProgress: "正在進行",
    regEnd: "已截止",
    tourEnd: "已完結",
    preparing: "準備中",
    startReg: "開放報名",
    menSingle: "男子單打",
    ladieSingle: "女子單打",
    allSingle: "全性別單打",
    allDouble: "全性別雙打",
    ladieDouble: "女子雙打",
    mixDouble: "混合雙打",


    //Tour Timetable
    roundRobin: "小組循環",
    round_robin: "小組循環",
    elimination: "淘汰",
    eliminate: "淘汰",
    buddiesWinter: "香港冬季網球 \"精英盃\"",
    buddiesSummer: "香港夏季網球 \"精英盃\"",
    fakeCup: "香港網球 \"偽滿貫\"",
    miniSlam: "香港網球 \"小滿貫\"",
    allGender: "不限男女",
    singles: "單打",
    doubles: "雙打",
    men: "男子",
    ladie: "女子",
    tourPeriod: "賽期",
    maybeTitle: "實際情況",
    maybeMessage: "最終安排取決於實際情況，未必能夠如期舉行",

    //Rank Page
    rankSingle: "單打",
    rankDouble: "雙打",
    rankNumber: "排名",
    rankPlayer: "球員",
    rankPoints: "排名分",
    updating: "更新中",
    rankRate: "勝負",
    eloNote: "每日深夜更新一次，使用 ELO 排名公式",
    rankTwoYears: "只計算兩年內有比賽的球員",
    in2year: "兩年內",

    // Draw Page
    round1: '1圈',
    round2: '2圈',
    round3: '3圈',
    round4: '4圈',
    round5: '5圈',
    round6: '6圈',
    round7: '7圈',
    roundQF: '8強',
    roundSF: '4強',
    roundF: '決賽',
    noTime: '未有時間',
    noPlace: '未有地點',
    noName: '未有',
    orBefore: '限期:',
    seed: '種子球員',
    drawRank: '分組排名',
    completeGame: '完成比賽',
    allWin: '總贏比賽',
    winGame: '凈贏盤數',
    winSet: '凈贏局數',
    tempPoint: '暫時總分',
    groupDeadline: '限期',
    noGRank: '未有',
    records: '記錄',
    penaltyPt: '罰分',
    noPenalty: '沒有',
    byeDraw: '輪空',
    winner: '勝出者',
    playerRankings: '球員排名',
    groupPoints:'組分',

    //Contact Page
    contactUs: "聯絡我們",
    emergencyContact: "緊急聯絡",
    businessContact: "商業合作",
    emergencyNote: "只限緊急用途",
    generalEnquiry: "一般查詢",

    //Log in Page
    login: "登入",
    register: "註冊",
    newhere: "新用戶?",
    createAnAccount: "建立新帳號",
    username: "用戶名",
    password: "密碼",
    forgotPassword: "忘記密碼 ?",
    fblogin: "Facebook 登入",
    loginSuccess: "登入成功",
    loginFail: "登入失敗",
    loggedOut: "已登出",
    logout: "登出",
    alreadyLogin: "已經登入",
    loginAgain: "請重新登入",
    pleaseLogin: "請先登入",
    inputUsername: "請輸入用戶名",
    inputPassword: "請輸入密碼",

    //Register Page
    alreadyRegister: "已登記?",
    loginHere: "按此登入",
    confirmPassword: "確認密碼",
    submit: "提交",
    OR: "或",
    normalRegister: "普通註冊",
    registerFail: "註冊失敗",
    registerSuccess: "註冊成功，請嘗試登入",
    minUsername: "最少 3 字符",
    maxUsername: "最多 15 字符",
    requiredUsername: "由 3 至 15 字符組成",
    noSpaceAllow: "不允許包含空格",
    usedUsername: "用戶名已被使用",
    usernameNote: "公開顯示 不能修改",
    phoneLabel: "電話",
    phoneNote: "WhatsApp 聯絡用途",
    minPassword: "最少 6 字符",
    maxPassword: "最多 18 字符",
    requiredPassword: "由 6 至 18 字符組成",
    matchPassword: "密碼不相同",
    emailFormat: "Email 格式不正確",
    requiredEmail: "請輸入 Email",
    usedEmail: "Email 已被使用",
    requiredMobile: "請輸入手機號碼",
    facebookExist: "Facebook 用戶已被登記，如需協助請聯絡我們",

    //Forgot Password
    recoverPassword: "找回密碼",
    emailNote: "輸入註冊時的 Email",
    recoverFail: "找回密碼失敗",
    noEmail: "沒有找到此登記 Email",
    emailFail: "發送郵件失敗，請聯絡我們",
    emailSent: "重置密碼郵件已發送",
    emailSentMessage: "請查閱你的郵箱和垃圾郵件",

    //Reset Password
    resetBtn: "重置密碼",
    resetFail: "重置密碼失敗，請聯絡我們",
    passwordFail: "重置密碼含有非法字符",
    resetSuccess: "成功重置密碼，請重新登入",

    //Account Setting
    accountSetting: "設置",
    setProfile: "個人資料",
    setPasswordEmail: "密碼 & 電郵",
    usernameLabel: "用戶名",
    balance: "結餘",
    area: "首選比賽地區",
    mobile: "手機",
    gender: "性別",
    birthday: "生日",
    selfIntro: "自我介紹",
    practiceAfter: "賽後練習",
    saveBtn: "保存",
    regionPH: "你的首選比賽地區",
    region0: "其他",
    region1: "中西區",
    region2: "灣仔",
    region3: "東區",
    region4: "南區",
    region5: "油尖旺",
    region6: "深水埗",
    region7: "九龍城",
    region8: "黃大仙",
    region9: "觀塘",
    region10: "葵青",
    region11: "荃灣",
    region12: "屯門",
    region13: "元朗",
    region14: "北區",
    region15: "大埔",
    region16: "沙田",
    region17: "西貢",
    region18: "離島",
    genderOther: "其他",
    genderMale: "男",
    genderFemale: "女",
    practiceYes: "會",
    practiceNo: "不會",
    practiceOther: "其他",
    settingSuccess: "成功保存",
    settingError: "保存失敗",
    oldPassword: "舊密碼",
    newPassword: "新密碼",
    confirmNewPassword: "確認新密碼",
    inputOldPassword: "請輸入舊密碼",
    matchConfirmPassword: "'新密碼' 和 '確認新密碼' 不相同",
    inputNewConfirmPassword: "請輸入 '確認新密碼'",
    samePassword: "'新密碼' 和 '舊密碼' 不能相同",
    setAvatar: "修改頭像",
    avatarType: "只限檔案格式 : jpg, jpeg, png",
    avatarWidthHeight: "建議圖片大小 : 300 x 300 px",
    avatarSize: "最大檔案大小 : 1000 KB",
    avatarNoImage: "請選擇圖片",
    fbconnect: "Facebook 連結",
    fbconnectFail: "Facebook 連結失敗",
    fbconnectSuccess: "Facebook 連結成功",

    //User Page
    drawPlayer: "球員",
    scoreDraw: "打和",
    singleRank: "單打排名",
    singleHighRank: "最高單打排名",
    doubleRank: "雙打排名",
    doubleHighRank: "最高雙打排名",
    winLose: "勝 / 負",
    recentMatch: "最近賽程",
    matchRecord: "對賽記錄",
    winLosePoint: "勝負",
    rankPoint: "排名分",
    reschedule: "改期",
    rescheduled: "已改期",
    normal: "正常",
    noRecord: "未有記錄",
    time: "時間",
    place: "地點",
    tempScore: "暫時分數",
    group: "組別",
    score: "分數",
    won: "勝",
    lost: "負",
    points: "分",
    retire: "棄權",
    drawEven: "打和",
    notCount: "不計算",
    tour: "比賽",
    single: "單打",
    double: "雙打",
    // Schedule Pages
    round: "回合",
    opponent: "對手",
    contact2: "聯絡",
    deadline2: "期限",
    repoint: "上報",
    submitter: "提交者",
    submitTime: "提交時間",
    editRecord: "最近5次報分記錄",
    oppoLocation: "對手首選",
    byeRound: "輪空",
    ifDone: "完成",
    notDone: "待定",
    sStatus: "狀態",
    yesStay: "會",
    noStay: "不會",
    cwd: "中西區",
    wanChai: "灣仔",
    eastD: "東區",
    southD: "南區",
    ytm: "油尖旺",
    ssp: "深水埗",
    klnCity: "九龍城",
    wts: "黃大仙",
    kwunTong: "觀塘",
    kwaiTsing: "葵青",
    tsuenWan: "荃灣",
    tuenMun: "屯門",
    yuenLong: "元朗",
    northD: "北區",
    taiPo: "大埔",
    shaTin: "沙田",
    saiKung: "西貢",
    island: "離島",
    other: "其他",
    quitTour: "退賽",
    noGame: "沒有資料",

    //Report Page
    confirm: "確定",
    cancel: "取消",
    set1: "第一局",
    set2: "第二局",
    set3: "第三局",
    tiebreak: "決勝局",
    updated: "成功更新",
    updateFailed: "更新失敗，請聯絡我們",
    haveWinner: "不能更新已完成的比賽，如有需要請聯絡我們",
    pleaseReset: "如需要更新已完成的比賽，請進行重置",
    remark: "備註",
    success: "成功",
    fail: "失敗",
    error: "發生錯誤",
    date: "日期",
    startTime: "開始時間",
    player: "球員",
    reset: "重置",

    //Apply Players
    order: "次序",
    payFee: "繳費",
    paid: "已繳費",
    notPaid: "未繳費",
    penalty: "扣分",
    adminNote: "管理員備注",
    adminAction: "行動",
    adminPaid: "已繳",
    adminPaidDepo: "已繳 & 按金",
    adminWithdraw: "撤回",
    adminQuitTour: "退出",
    adminPaidSuccess: "已轉繳費",
    adminWithdrawSuccess: "已撤回報名",
    adminQuitTourSuccess: "已成功退賽",
    newbie: "新人",
    partner: "隊友",
    addPlayer: "單打手動報名 (UID)",
    addDoublePlayer1: "雙打手動報名球員1 (UID)",
    addDoublePlayer2: "雙打手動報名球員2 (UID)",

    //Apply Tour
    appliedTitle: "已成功報名",
    applied: "已報名",
    partnerPay: "需由隊友繳費",
    applyStartTitle: "開始報名",
    applyEndtTitle: "比賽已截止",
    tourStartTitle: "比賽進行中",
    tourEndTitle: "比賽已結束",
    tourPrepareTitle: "比賽準備中",
    appliedContent: "請準時繳交報名費，逾期將被取消資格，如有問題請聯絡我們",
    applyStartContent: "請準時繳交報名費，逾期將被取消資格，如有問題請聯絡我們",
    applyEndContent: "比賽已截止，如有問題請聯絡我們",
    tourStartContent: "比賽進行中，如有問題請聯絡我們",
    tourEndContent: "比賽已結束，如有問題請聯絡我們",
    tourPrepareContent: "比賽準備中，如有問題請聯絡我們",
    confirmApply: "確認報名",
    confirmInfo: "確認資料",
    modifyApply: "修改資料",
    goPay: "前往繳費",
    memo: "備注",
    partnerUsername: "隊友用戶名",
    requiredPartnerUsername: "請輸入隊友用戶名",
    applyBtn: "前往報名頁",
    yourMobile: "你的手機",
    partnerUsernameError: "隊友用戶名錯誤",
    applyGenderError: "性別與比賽不符",
    partnerReg: "隊友用戶名不存在",
    uniPartnerRegErr: "全性別雙打隊友用戶名不存在",
    mixPartnerRegErr: "混合雙打隊友用戶名不存在",
    womenPartnerRegErr: "女子雙打隊友用戶名不存在",
    memoNote: "任何成績記錄將有助分配到更高組別",
    fakeCantApply: "未符合資格，如有問題請聯絡我們",
    emptyTour: "請選擇比賽項目",
    applyFeeCount: "報名費計算: ",
    applyFee: "報名費",
    deposit: "預備金",
    creditLeft: "戶口餘額",

    //Payment
    eachDouble: "每隊",
    payNote: "如遇上問題請嘗試按 F5 刷新網頁，如有問題請聯絡我們",
    hkd: "港元",
    clickPay: "按此網上支付",

    //Error Page
    msg404: "這裡好像什麼都沒有",
    returnHome: "回到首頁",
    systemError: "系統錯誤",
    gotoHome: "回到首頁",
    somethingWrong: "發生錯誤，請稍後再嘗試。",


  },
  es: {
    dashboard: "Tablero",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Paginas",
    profile: "Perfil",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Ajustes",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    calendarApp: "Calendario",
    subscriptions: "Suscripciones",
    getStarted: "Empezando",
    subscriptionList: "Lista De Suscripción",
    addSubscription: "Añadir Suscripción",
    viewSubscription: "Suscripción",
  },
  de: {
    dashboard: "Instrumententafel",
    layoutBuilder: "Layout-Builder",
    craft: "Hergestellt",
    pages: "Seiten",
    profile: "Profil",
    profileOverview: "Überblick",
    projects: "Projekte",
    campaigns: "Kampagnen",
    documents: "Unterlagen",
    connections: "Anschlüsse",
    wizards: "Zauberer",
    horizontal: "Horizontal",
    vertical: "Vertikal",
    account: "Konto",
    accountOverview: "Überblick",
    settings: "Die Einstellungen",
    authentication: "Authentifizierung",
    basicFlow: "Grundfluss",
    signIn: "Einloggen",
    signUp: "Anmelden",
    passwordReset: "Passwort zurücksetzen",
    error404: "Fehler 404",
    error500: "Fehler 500",
    apps: "Apps",
    chat: "Plaudern",
    privateChat: "Private Chat",
    groupChat: "Privater Chat",
    drawerChat: "Gruppenchat Schubladen-Chat",
    widgets: "Widgets",
    widgetsLists: "Listen",
    widgetsStatistics: "Statistiken",
    widgetsCharts: "Diagramme",
    widgetsMixed: "Gemischt",
    widgetsTables: "Tabellen",
    widgetsFeeds: "Einspeisungen",
    changelog: "Änderungsprotokoll",
    docsAndComponents: "Dokumente & Komponenten",
    megaMenu: "Mega-Menü",
    exampleLink: "Beispiellink",
    modals: "Modale",
    general: "Allgemeines",
    inviteFriends: "Freunde Einladen",
    viewUsers: "Benutzer Anzeigen.",
    upgradePlan: "Upgrade-Plan",
    shareAndEarn: "Teilen & Verdienen",
    forms: "Formen",
    newTarget: "Neues Ziel",
    newCard: "Neue Karte",
    newAddress: "Neue Adresse",
    createAPIKey: "Api-Key Erstellen",
    twoFactorAuth: "Zwei Faktor Auth.",
    createApp: "App Erstellen",
    createAccount: "Benutzerkonto Erstellen",
    activity: "Aktivität",
    documentation: "Dokumentation",
    components: "Bauteile",
    resources: "Ressourcen",
    customers: "Kunden",
    gettingStarted: "Einstieg",
    customersListing: "Kundenauflistung",
    customerDetails: "Kundenangaben",
    calendarApp: "Kalender",
    subscriptions: "Abonnements",
    getStarted: "Einstieg",
    subscriptionList: "Abonnementliste",
    addSubscription: "Subskription Hinzufügen.",
    viewSubscription: "Abonnement Anzeigen.",
  },
  ja: {
    dashboard: "ダッシュボード",
    layoutBuilder: "レイアウトビルダー",
    craft: "作成された",
    pages: "ページ",
    profile: "プロフィール",
    profileOverview: "概要",
    projects: "プロジェクト",
    campaigns: "キャンペーン",
    documents: "書類",
    connections: "接続",
    wizards: "ウィザード",
    horizontal: "横",
    vertical: "垂直",
    account: "アカウント",
    accountOverview: "概要",
    settings: "設定",
    authentication: "認証",
    basicFlow: "基本的な流れ",
    signIn: "サインイン",
    signUp: "サインアップ",
    passwordReset: "パスワードのリセット",
    error404: "エラー404",
    error500: "エラー 500",
    apps: "アプリ",
    chat: "チャット",
    privateChat: "プライベートチャット",
    groupChat: "グループチャット",
    drawerChat: "ドロワーチャット",
    widgets: "ウィジェット",
    widgetsLists: "リスト",
    widgetsStatistics: "統計",
    widgetsCharts: "チャート",
    widgetsMixed: "混合",
    widgetsTables: "テーブル",
    widgetsFeeds: "フィード",
    changelog: "変更ログ",
    docsAndComponents: "ドキュメントとコンポーネント",
    megaMenu: "メガメニュー",
    exampleLink: "リンク例",
    modals: "モーダルズ",
    general: "一般",
    inviteFriends: "友達を招待",
    viewUsers: "ユーザーを表示します",
    upgradePlan: "アップグレードプラン",
    shareAndEarn: "シェア＆稼働",
    forms: "フォーム",
    newTarget: "新しいターゲット",
    newCard: "新しいカード",
    newAddress: "新しいアドレス",
    createAPIKey: "Apiキーを作成します",
    twoFactorAuth: "2つの要因Auth",
    createApp: "アプリを作成します",
    createAccount: "アカウントを作成する",
    activity: "アクティビティ",
    documentation: "ドキュメンテーション",
    components: "コンポーネント",
    resources: "資力",
    customers: "お客様のお客様",
    gettingStarted: "入門",
    customersListing: "顧客のリスト",
    customerDetails: "お客様の詳細",
    calendarApp: "カレンダー",
    subscriptions: "購読",
    getStarted: "入門",
    subscriptionList: "サブスクリプションリスト",
    addSubscription: "サブスクリプションを追加します",
    viewSubscription: "購読を見る",
  },
  fr: {
    dashboard: "Générateur de mise",
    layoutBuilder: "En page",
    craft: "Fabriqué",
    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    settings: "Paramètres",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "SS'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    chat: "Discuter",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    activity: "Activité",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
